import React, { useEffect, useState } from 'react';
import ReserveFailed from './ReserveFailed';
import ReserveSuccess from './ReserveSuccess';
import {useSearchParams} from 'react-router-dom';
import axios from '../url/axios';

function ReserveFinish() {

  const [paymentStatus, setPaymentStatus] = useState('');
  const [searchParams] = useSearchParams();

 const billId = searchParams.get('billplz[id]').toString();
  const paid = searchParams.get('billplz[paid]').toString();
 const paid_at = searchParams.get('billplz[paid_at]').toString();
 const x_signature = searchParams.get('billplz[x_signature]').toString();
  
 useEffect(() => {
  const sendData = {billId: billId, paid: paid, paid_at: paid_at, x_signature: x_signature}
  axios.post("/booking-status", sendData).then((response) => {
    if(response.data.invalid){
       setPaymentStatus('invalid')
    }else if(response.data.success){
      setPaymentStatus('success')
    }
  })
 }, [])

console.log(paymentStatus)

  return (
    <div>
      <div
        className="row justify-content-center"
        style={{ backgroundColor: "#333" }}
      >
        <div className="title">
       {paymentStatus === 'success' && (<ReserveSuccess />)} { paymentStatus === 'invalid' && (<ReserveFailed />)}
              </div>

      </div>
      </div>
  )
}

export default ReserveFinish
